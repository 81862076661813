@import "~jsoneditor/dist/jsoneditor.min.css";
@import "variables";
@import "vendors/material2/material2";
@import "pages/demo";
@import "pages/auth";
@import "pages/error";
@import "pages/lock-screen";
@import "pages/profile";
@import "pages/invoice";
@import "pages/dashboard";
@import "pages/maintenance";
@import "pages/about";
@import "pages/terms";
@import "pages/blog";
@import "~@ng-select/ng-select/themes/default.theme.css";
body p-breadcrumb .ui-breadcrumb {
    background: transparent;
    border-radius: 0;
    text-transform: uppercase;
    border-width: 0px;
    font-size: 12px !important;
    .pi-chevron-right:before {
        content: "/";
    }
    LI.ui-breadcrumb-home A.ui-menuitem-link {
        font-size: 18px !important;
    }
    .ui-menuitem-link {
        color: #002A61 !important;
        /*#002A61 $light-blue*/
        font-size: 13px !important;
    }
    .ui-menuitem-link:hover {
        text-decoration: underline !important;
    }
    .ui-menuitem-link[href="#"] {
        color: #999 !important;
        cursor: default;
    }
    .ui-menuitem-link[href="#"]:hover {
        text-decoration: unset !important;
    }
}

.mat-calendar-content {
    height: 100%;
}

.pi-sort-up,
.pi-sort-down,
.ui-sortable-column.ui-state-highlight {
    color: black !important
}

.mat-calendar-table {
    height: 80%;
}

app-weekday-selection {
    width: 100%;
    padding: 0 15px
}

.mat-step-header .mat-step-icon,
.mat-step-header .mat-step-icon-not-touched {
    display: none;
}

.mat-step-header[aria-selected=true] {
    background: $principal /*#2196f3*//*#e52645*/;
}

.mat-step-header[aria-selected=true]:hover {
    background: #69b4f1;
}

.mat-step-text-label {
    font-size: larger
}

.mat-step-header[aria-selected=true] .mat-step-text-label {
    color: white
}

.mat-horizontal-stepper-header-container .mat-step-header:nth-child(1) {
    border-left: 1px solid #999;
}

.mat-step-header {
    margin-bottom: 10px;
    border-right: 1px solid #999;
    border-top: 1px solid #999;
    border-bottom: 1px solid #999;
}

.mat-horizontal-stepper-header-container {
    display: flex;
}

.mat-horizontal-stepper-header-container>* {
    flex: 1
}

.mat-stepper-horizontal-line {
    display: none;
}

.mat-horizontal-content-container {
    padding: 0px !important;
}

app-elasticsearch-filter mat-card {
    margin-bottom: 1em
}

button.bg-success {
    color: white;
    &[disabled]:not(.mat-stroked-button) {
        background-color: rgb(228, 228, 228) !important;
        color: rgba(0, 0, 0, 0.26)!important;
        // background-color: rgb(211, 211, 211) !important;
        cursor: not-allowed;
        span {
            background-color: transparent !important;
        }
        &:hover {
            background-color: rgb(211, 211, 211) !important;
        }
    }
}



.box-header {
    &.bg-color {
        background-color: $light-blue;
        color: $white;
        border-radius: 3px 3px 0px 0px;
    }
    &.squared {
        border-radius: 0;
    }
    button {
        top: -9px;
        &.mat-stroked-button {
            border-width: 2px;
        }
    }
    .mat-stroked-button {
        transition: all 100ms ease-in-out;
        background-color: transparent !important;
        float: right;
        &.bg-success {
            border-color: white;
            color: white;
            &:hover {
                background-color: #66BB6A !important;
                .mat-button-focus-overlay {
                    opacity: 0;
                }
            }
            &[disabled] {
                border-color: rgba(0, 0, 0, .26);
                color: rgba(0, 0, 0, .66);
                background-color: rgba(156, 156, 156, 0.26) !important;
                cursor: not-allowed;
            }
        }
    }
    i.slide-icon {
        width: 25px;
        height: 15px;
        float: left;
        cursor: pointer;
    }
}

.theme-bradesco {
    .box-header {
        &.bg-color {
            background-color: $red-bradesco;
            color: $white;
            border-radius: 3px 3px 0px 0px;
        }
    }
}

.box-flex {
    display: flex;
    flex-direction: row;
    &.align-bottom {
        align-items: flex-end;
    }
    &.align-center {
        align-items: flex-center;
    }
    &.align-top {
        align-items: flex-start;
    }
}

.mat-checkbox label {
    font-weight: bold;
    margin-top: 6px;
}

.ui-paginator-bottom {
    height: 34px;
    background: white !important;
    line-height: 20px
}

.ui-paginator-prev,
.ui-paginator-next,
.ui-paginator-last,
.ui-paginator-first {
    color: $primary !important;
}

.ui-paginator-element {
    font-size: 1.25em;
}

.ui-paginator-pages {
    margin-top: -7px;
}

p-paginator .ui-paginator-bottom .ui-paginator-pages>.ui-state-active {
    border-radius: 0;
    background: $primary;
}

.theme-bradesco {

    .ui-paginator-prev,
    .ui-paginator-next,
    .ui-paginator-last,
    .ui-paginator-first {
        color: $red-bradesco !important;
    }

    p-paginator .ui-paginator-bottom .ui-paginator-pages>.ui-state-active {
        border-radius: 0;
        background: $red-bradesco;
    }
}

#page-container .ui-table .ui-table-tbody>tr:nth-child(odd) {
    background-color: #f7f7f7 !important;
}

.ui-table .ui-table-thead>tr>th,
.ui-table .ui-table-tfoot>tr>td,
#page-container .ui-table .ui-table-tbody>tr:nth-child(even) {
    background-color: white !important;
}

td,
th {
    padding: 10px !important;
}

.ui-table table {
    table-layout: auto !important;
    width: 100%
}

.ui-table .ui-table-data>tr>td.ui-editable-column {
    padding: 1px !important;
}

.ui-table * {
    border: 0px !important;
}

.top-nav-right>ul {
    font-size: 16px;
    font-weight: bolder;
}

.mat-raised-button {
    min-width: 135px !important;
}

.clickable {
    cursor: pointer;
}

p-table {
    width: 100%
}

ng-select>div {
    border-radius: 0.2rem;
    height: 36px;
    padding: 3px 0
}

.app-sidebar.bg-color-light .nav a {
    color: #002A61 !important;
}

.app-sidebar .nav ul li>a,
.app-sidebar .sidebar-content .nav>li>a {
    padding: 8px 15px !important;
    display: flex;
}

.app-sidebar.bg-color-light .nav a:hover {
    background-color: rgba(0, 0, 0, .12) !important;
}

.iconeCard {
    position: absolute;
    left: -8px;
    top: -8px;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 3px;
    /* border: #D9D9D9 solid 1px; */
    width: 30px;
    height: 30px;
    font-size: 25px !important;
    box-shadow: rgba(0, 0, 0, 0.10) 0px 0px 2px 1px;
}

.label-input {
    display: flex;
    align-items: center;
    font-weight: bold;
}

.mat-input-hint-wrapper mat-hint {
    color: red !important;
}

/* width */

::-webkit-scrollbar {
    width: 10px;
}

/* Track */

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.sidebar-content {
    padding-top: 5px;
}

#body .mat-dialog-container select-dropdown>div {
    z-index: 9999;
    position: absolute;
}

mat-dialog-container{
    overflow: hidden!important;
}

.lista-importacao {
    margin: 1.5em;
    border: 1px solid darkgray;
    border-radius: 10px;
    .header {
        border-radius: 10px 10px 0 0;
        background-color: #F7F4ED;
        border-bottom: 1px solid darkgray;
        padding: 1em;
    }
    .container-arquivo {
        height: 150px;
        .lista-arquivo {
            list-style-type: none;
            position: absolute;
            margin-top: 10px;
            .arquivo {
                display: inline-block;
                margin-right: 10px
            }
        }
        .display-mensagem-arquivo {
            position: relative;
            text-align: center;
            top: 45%;
            left: 35%;
            font-size: 2em;
        }
        .input-arquivo {
            opacity: 0;
            height: 100%;
            width: 100%;
        }
    }
}

li .ui-state-active {
    background-color: transparent;
}

.mat-check {
    background: white;
    border: 2px solid #888 !important;
    border-radius: 2px
}

#upload-header {
    padding: 10px
}

NG-SELECT{
    background: #ffffff !important;       
}

fieldset[disabled] {
    button {
        background: #e9ecef !important;
        color: black;
        cursor: not-allowed;
    }
    ng-select,
    app-select {
        background: #e9ecef !important;
        cursor: not-allowed !important;
    }
    .mat-radio-label {
        cursor: not-allowed !important;
    }
    .mat-radio-outer-circle {
        border-color: gray !important;
    }
    .mat-radio-inner-circle {
        background-color: gray !important;
    }
    input {
        cursor: not-allowed;
    }
    button span,
    button mat-icon {
        color: black !important;
    }
    mat-icon {
        color: #babcbf !important;
        cursor: not-allowed;
    }
}

*[draggable="true"]:hover {
    cursor: move;
    background-color: #dfdfe0 !important;
}

.droppable {
    outline: 1px dashed black !important;
}

mat-radio-button {
    margin-right: 5px;
}

.ui-listbox {
    width: 100% !important;
    .ui-widget-header {
        padding: 0px;
        .ui-inputtext {
            font-size: 14px !important;
        }
    }
    .ui-state-highlight {
        background-color: $principal /*#2196f3*//*#e52645*/
    }
    .ui-listbox-list-wrapper {
        height: 150px;
        overflow-y: auto
    }
}

.small-negative-margin-left {
    margin-left: -25px;
}

#body.swal2-height-auto {
    height: 100% !important;
}

.mat-tab-label-active {
    opacity: 1 !important;
}

.datepicker {
    height: 36px;
    .mat-input-flex.mat-form-field-flex {
        border: 1px solid lightgray !important;
        border-radius: 5px !important;
        height: 36px !important;
    }
    .mat-form-field-label-wrapper.mat-input-placeholder-wrapper.mat-form-field-placeholder-wrapper {
        padding-top: 0px !important;
    }
    input.mat-input-element {
        margin-top: 0px !important;
        padding-left: 0.5em;
    }
    .mat-form-field-infix {
        padding: 0px;
        height: 36px;
        border: 0px;
    }
    .mat-input-underline {
        visibility: hidden;
    }
    .mat-input-suffix {
        padding-top: 5px;
        padding-right: 3px;
    }
}

/* Block UI Wrapper (Backdrop) */

div.block-ui-wrapper {
    background-color: rgba(240, 240, 240, 0.7);
    opacity: 1;
}

/* Spinner */

div.block-ui-wrapper .loader {
    border-color: #fff;
    /* Circle color */
    border-left-color: $principal /*#2196f3*//*#e52645*/;
    /* Spinning section in circle color */
}

/* Message */

div.block-ui-spinner .message {
    color: #e52645;
    /* Loading text color */
}

app-preview-dados-estrategia table tbody td {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.card-slide {
    transition: all 400ms ease-in-out;
    max-height: calc(100% - 10px);
    opacity: 1;
    &.card-slide-lista {
        // max-height: 500px;
    }
    &.card-slide-filtro {
        // max-height: 900px;
    }
    &.card-slide-out {
        max-height: 0px;
        opacity: 0;
        margin: 0;
        padding-top: 0;
        padding-bottom: 0;
    }
}

.iconCardIn {
    transform: rotateZ(-180deg);
    margin-top: 5px;
    transition: all 400ms ease-in-out;
}

.iconCardOut {
    transform: rotateZ(0deg);
    margin-top: -3px;
    transition: all 400ms ease-in-out;
}

.close-button {
    float: right;
}

.ui-listbox-list {
    overflow: hidden;
    .ui-listbox-item {
        position: relative;
        .ui-chkbox {
            position: absolute;
            width: 102%;
            height: 102%;
            .ui-chkbox-box {
                margin-top: 5px;
            }
        }
        >span {
            margin-left: 20px;
        }
    }
}

.mat-card {
    &>.mat-card-actions {
        margin: 0 0 -0.5rem 0 !important;
    }
    &.can-hover:hover {
        box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
        transition: all 100ms ease-in-out;
    }
}

.form-group {
    .mat-radio-group {
        display: block;
        margin-top: 0.6rem;
    }
}

.mat-tab-label-active{
    background: $principal /*#2196f3*//*#e52645*/ ;
}

.theme-bradesco .mat-tab-label-active {
    background-color: $red_bradesco !important;
}

.mat-dialog-container ng-select select-dropdown div.below, .mat-tab-body ng-select select-dropdown div.below {
    position: fixed !important;
    top: unset !important;
    left: unset !important;
    z-index: 1000;
}


.mat-dialog-container ng-select.grid select-dropdown div.below,
.mat-tab-body ng-select.grid select-dropdown div.below {
    position: absolute !important;
    top: unset !important;
    left: unset !important;
    z-index: 1000;
}
.mat-dialog-container  ng-select.grid select-dropdown div.below div.options, 
.mat-tab-body ng-select.grid select-dropdown div.below div.options{
    max-height: 130px !important;
}


@media only screen and (max-width: 576px) {
    .mat-card.filtro>.mat-card-content,
    .box-body {
        .col-6:not(:nth-child(-n+2)),
        .col-12:not(:first-child) {
            margin-top: 1rem;
            text-align: left !important;
        }
    }
    .mat-card.filtro>.mat-card-actions .col-12:not(:first-child) {
        margin-top: 0.5rem;
        text-align: left !important;
    }
}

app-card-with-header > .box-body > *{
    width: 100%;
    padding: 0 1em;
}
.single-list-box .mat-list-item:hover, .mat-list-item.selected{
    border-radius: 5px;
    cursor: pointer;
    color: white!important;
    background-color: $principal /*#2196f3*//*#e52645*/;
}

.numeric-column{
    text-align: right;
}

.date-column
{
    text-align: center;
}

.list-box-label{
    padding: 0px 5px; 
    float:right; 
    margin-right: 5px; 
    border: 1px solid gray; 
    border-radius: 30px
}

app-custom-listbox select {
    height:37px;
    width:20px;
    border-color:lightgray; 
    border-radius: 0 3px 3px 0;
    color: gray;

    option {
        color:black;
    }
}

app-date-math-filter{
    width: 100%;
}

.mat-tab-label-content{
    color: white!important;
}

p-table .ui-table textarea, p-table .ui-table ng-select, p-table .ui-table ng-select .below, p-table .ui-table .mat-radio-outer-circle {
    border: 1px solid lightgray!important; 
}

p-table .ui-table textarea, p-table .ui-table ng-select, p-table .ui-table ng-select .below{
    border-radius: 5px;
}

mwl-text-input-autocomplete-menu .dropdown-menu
{
    border: 1px solid rgba(0, 0, 0, 0.15)!important;
	width: 100%;
    left: 0!important;
    padding: 0.9em;
}

mwl-text-input-autocomplete-menu .dropdown-menu li > a
{
    width: 100%;
	display: inline-block;
}

mwl-text-input-autocomplete-menu .dropdown-menu li > a:hover
{
    background: var(--blue);
	color: white;
}

UL.MenuPrincipal.nav, UL.MenuPrincipal.nav UL{
    font-size: 13px !important;
}

.tab-top .mat-tab-label {
    &:not(.mat-tab-label-active) {
        background-color: rgba(0, 0, 0, .3);
    }
}

.tab-middle .mat-tab-label {
    color: #333333 !important;  
    background-color: #f4f4f4;
    border-top: 1px solid #aaa8a8 !important;
    border-left: 1px solid #aaa8a8 !important;
    border-right: 1px solid #aaa8a8 !important;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    margin-left: 1px;
    margin-right: 1px;

    .mat-tab-label-content {
        color: #333333 !important;
    }    
}

.tab-middle .mat-tab-label:hover {
    &:not(.mat-tab-label-active) {
        background-color: #dadada !important;
    }
}


.tab-middle .mat-tab-label.mat-tab-label-active {
    border-top: 1px solid white !important;
    border-left: 1px solid white !important;
    border-right: 1px solid white !important;
    z-index: 2000 !important;
    color: white !important;
    background-color:$blue !important;
   

    .mat-tab-label-content {
        color: white !important;
    }        
}

// .mat-icon.mat-primary {
//     color: $blue !important;
// }

.theme-bradesco .mat-icon.mat-primary {
    color: $red-bradesco !important;
}

.theme-bradesco .tab-middle .mat-tab-label.mat-tab-label-active {
    background-color: $red-bradesco !important;
}

.tab-middle .mat-tab-labels{
    padding-left: 5px;
}

json-editor {
    .jsoneditor {
    border-color: $light-blue;
    }

    .jsoneditor-menu {
        background-color: $light-blue;
        border-bottom: 1px solid $light-blue;
    }

    .jsoneditor-menu a.jsoneditor-poweredBy {
        font-size: 0pt !important;
    }
}

json-editor,
json-editor .jsoneditor,
json-editor > div,
json-editor jsoneditor-outer {
  height: 500px;
}